@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.danger{
  color: red;
}
.mb-0{margin-bottom: 0px!important;}  

.form-group{margin-bottom: 15px!important;} 
.pagination {
  
    list-style-type: none;
      display: flex;
      justify-content: end;
  
}

.page-item{
  margin: 6px;
}